import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Drawer,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import ChatIcon from '@mui/icons-material/Chat';
import DescriptionIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import HistoryIcon from '@mui/icons-material/History';
import FeedbackIcon from '@mui/icons-material/Feedback';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { styled } from '@mui/material/styles';

// Styled component for sidebar header
const SidebarHeader = styled(Typography)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.text.secondary
}));

// **Customization: DrawerHeader with blue theme**
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.primary.main, // Set your desired blue color here
    color: '#fff', // Set text color to contrast with the blue background
}));

export default function Sidebar({ isCollapsed, drawerWidth }) {
    const drawerStyle = {
        width: 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
        },
    }
    const [expanded, setExpanded] = useState('');
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Drawer
            sx={drawerStyle}
            variant="persistent"
            anchor="left"
            open={!isCollapsed}
        >
            <DrawerHeader>
                <Box sx={{ px: 2, py: 1, width: '100%', textAlign: 'left' }}>
                    <Link style={{ textDecoration: "none", color: '#fff' }} to="/Dashboard">
                        <Typography variant="h6" component="div" align="left">
                            KlonIT
                        </Typography>
                    </Link>
                </Box>
            </DrawerHeader>
            <Divider />

            <List component="nav">
                <Accordion expanded={expanded === 'main'} onChange={handleChange('main')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="main-content"
                        id="main-header"
                    >
                        <SidebarHeader>Main</SidebarHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/RetrainChatbot" >
                                <ListItemIcon><DashboardIcon /></ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItemButton>
                        </ListItem>
                    </AccordionDetails>
                </Accordion>



                <Accordion expanded={expanded === 'config'} onChange={handleChange('config')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="config-content"
                        id="config-header"
                    >
                        <SidebarHeader>Configuration</SidebarHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/configuration">
                                <ListItemIcon><SettingsIcon /></ListItemIcon>
                                <ListItemText primary="Website Configuration" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/avatar">
                                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                <ListItemText primary="Avatar" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/audio">
                                <ListItemIcon><AudioFileIcon /></ListItemIcon>
                                <ListItemText primary="Audio & Language" />
                            </ListItemButton>
                        </ListItem>
                        {/* <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/thumbnail-color">
                                <ListItemIcon><ColorLensIcon /></ListItemIcon>
                                <ListItemText primary="Thumbnail and Color" />
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/ChatbotSettings">
                                <ListItemIcon><ChatIcon /></ListItemIcon>
                                <ListItemText primary="Chatbot Settings" />
                            </ListItemButton>
                        </ListItem>
                    </AccordionDetails>
                </Accordion>



                <Accordion expanded={expanded === 'data'} onChange={handleChange('data')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="data-content"
                        id="data-header"
                    >
                        <SidebarHeader>Data Sources</SidebarHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/add-website">
                                <ListItemIcon><LinkIcon /></ListItemIcon>
                                <ListItemText primary="Website" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/add-documents">
                                <ListItemIcon><DescriptionIcon /></ListItemIcon>
                                <ListItemText primary="Documents" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/add-image">
                                <ListItemIcon><ImageIcon /></ListItemIcon>
                                <ListItemText primary="Image" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/Voice">
                                <ListItemIcon><HeadphonesIcon /></ListItemIcon>
                                <ListItemText primary="Audio" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/Knowledge">
                                <ListItemIcon><AutoStoriesIcon /></ListItemIcon>
                                <ListItemText primary="Knowledge" />
                            </ListItemButton>
                        </ListItem>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'sessions'} onChange={handleChange('sessions')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="sessions-content"
                        id="sessions-header"
                    >
                        <SidebarHeader>Sessions</SidebarHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/chat-history">
                                <ListItemIcon><HistoryIcon /></ListItemIcon>
                                <ListItemText primary="Chat History" />
                            </ListItemButton>
                        </ListItem>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'improvements'} onChange={handleChange('improvements')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="improvements-content"
                        id="improvements-header"
                    >
                        <SidebarHeader>Response Improvements</SidebarHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/user-feedback">
                                <ListItemIcon><FeedbackIcon /></ListItemIcon>
                                <ListItemText primary="Feedbacks" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/question-improvements">
                                <ListItemIcon><QuestionMarkIcon /></ListItemIcon>
                                <ListItemText primary="Question Quality" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/answers-improvements">
                                <ListItemIcon><CheckCircleIcon /></ListItemIcon>
                                <ListItemText primary="Ground Truth" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/Pronoun">
                                <ListItemIcon><CheckCircleIcon /></ListItemIcon>
                                <ListItemText primary="Pronouns" />
                            </ListItemButton>
                        </ListItem>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'monetize'} onChange={handleChange('monetize')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="monetize-content"
                        id="monetize-header"
                    >
                        <SidebarHeader>Monetize</SidebarHeader>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItem disablePadding>
                            <ListItemButton component={NavLink} to="/add-monetize">
                                <ListItemIcon><AttachMoneyIcon /></ListItemIcon>
                                <ListItemText primary="Add Advertisements" />
                            </ListItemButton>
                        </ListItem>
                    </AccordionDetails>
                </Accordion>
            </List>
        </Drawer>
    );
}