import React, { useState, useEffect } from "react";
import axios from "axios";
import { Tabs, Tab } from '@mui/material';
import backendURL from "../../config";

export default function AddMonetize({ onClose }) {
  useEffect(() => {
    document.title = 'Advertisement | KlonIT Web Application';
  }, []);

  const [formData, setFormData] = useState({
    title: '',
    days: 7,
    page_content: '',
    page_url: '',
    is_default: false,
  });
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (event) => {
      event.preventDefault();
      const token = localStorage.getItem('access_token');
      setLoading(true);
      console.log("Form submission started with data:", formData);
  
      // Get current date and time
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
  
      // Prepare the data with the current date and time
      const dataToSend = {
          ...formData,
          upload_date: formattedDate
      };
      console.log("Data to be posted:", dataToSend);
    
      try {
        const response = await axios.post(`${backendURL}/add-monetize`, dataToSend, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        });
        console.log("API response:", response.data);
        setError(response.data.message);
        setRefresh(prev => !prev);
        setFormData({
          title: '',
          days: 7,
          page_content: '',
          page_url: '',
          is_default: false,
        });
      } catch (error) {
        console.error("Error submitting form:", error.response || error.message);
        setError(error.response ? error.response.data.message : "Error submitting data.");
      } finally {
        setLoading(false);
        console.log("Form submission completed.");
      }
    };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleCancel = () => {
    setFormData({
      title: '',
      days: 7,
      page_content: '',
      page_url: '',
      is_default: false,
    });
    onClose();  // Close the modal
  };

  return (
    <div>
      <main className="">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">Add Advertisements</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="mb-3 col-6 col-lg-6">
                        <label htmlFor="title" className="form-label">Title:</label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          className="form-control"
                          value={formData.title}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3 col-6 col-lg-6">
                        <label htmlFor="days" className="form-label">Days:</label>
                        <select
                          id="days"
                          name="days"
                          className="form-control"
                          value={formData.days}
                          onChange={handleChange}
                          required
                        >
                          <option value={7}>7</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                        </select>
                      </div>
                      <div className="mb-3 col-12 col-lg-12">
                        <label htmlFor="page_content" className="form-label">Page Content:</label>
                        <textarea
                          id="page_content"
                          name="page_content"
                          className="form-control"
                          rows="5"
                          value={formData.page_content}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      <div className="mb-3 col-8 col-lg-8">
                        <label htmlFor="page_url" className="form-label">Page URL:</label>
                        <input
                          type="text"
                          id="page_url"
                          name="page_url"
                          className="form-control"
                          value={formData.page_url}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-3 form-check col-2 col-lg-2">
                        <label htmlFor="" className="form-label">Featured:</label> <br/><br/>
                        <input
                          type="checkbox"
                          id="is_default"
                          name="is_default"
                          className="form-check-input"
                          checked={formData.is_default}
                          onChange={handleChange}
                          style={{marginLeft: "2px", marginRight: "8px"}}
                        />
                        <label htmlFor="is_default" className="form-check-label">Yes</label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                      >
                        {loading ? <><i className="fa fa-spinner fa-spin"></i> Saving...</> : "Save"}
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary mx-2"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
                {error && <div className="alert alert-warning mt-2">{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
