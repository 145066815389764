import React, { createContext, useState, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './theme'; // Import light and dark themes

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children }) => {
     const [mode, setMode] = useState('light');
     const theme = createTheme(mode === 'light' ? lightTheme : darkTheme);

     const toggleTheme = () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
     };

    return (
        <ThemeContext.Provider value={{ mode, toggleTheme }}>
            <ThemeProvider theme={theme}>
                 {children}
             </ThemeProvider>
        </ThemeContext.Provider>
    );
};