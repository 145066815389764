import React from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import Footer from './Footer'; // Import the Footer component
import Header from './Header';
import { styled } from '@mui/material/styles';

const drawerWidth = 300; // Define drawerWidth here

// Styled component to wrap the main content and ensure the footer sticks to the bottom.
const MainContentWrapper = styled(Box)(({ theme, isCollapsed }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh', // Ensure content takes up at least the viewport height
    marginLeft: !isCollapsed ? `${drawerWidth}px` : 0,
    transition: 'margin .3s',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
    },

}));
const ContentWrapper = styled(Box)(({theme}) =>({
    flex: 1, // Allow content to grow and push footer to the bottom
    padding: theme.spacing(3),
    
}));
const FooterWrapper = styled(Box)(({theme}) =>({
    padding: theme.spacing(2),
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
}))

function Layout({ children, isCollapsed, onToggleSidebar }) {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar
                isCollapsed={isCollapsed}
                drawerWidth={drawerWidth} // Pass drawerWidth as prop
            />
            <MainContentWrapper isCollapsed={isCollapsed}>
                 <Header toggleSidebar={onToggleSidebar} />
                <ContentWrapper>
                    {children}
                </ContentWrapper>
               <FooterWrapper>
                  <Footer />
                </FooterWrapper>
            </MainContentWrapper>
        </Box>
    );
}

export default Layout;