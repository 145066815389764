import React, { useState, useEffect } from "react";
import { ChatScriptsURL } from "../../config";
import backendURL from "../../config";
import { APPLICATION_URL } from "../../config";
import axios from "axios";

export default function ChatbotLive() {
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Chatbot Live | KlonIT Web Application";
    fetchDataAndInitializeChatbot();
  }, []);

  // Function to fetch data and initialize the chatbot
  const fetchDataAndInitializeChatbot = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = { Authorization: `Bearer ${token}` };

      // Make the API request
      const response = await axios.post(
        `${backendURL}/get-javascript`,
        {},
        { headers }
      );
      console.log("Get Data: ", response.data);

      // Initialize the chatbot with the fetched data
      if (response.data) {
        initializeChatbot(response.data.website_path, response.data.website_url);
      }
    } catch (error) {
      console.error("Full error details:", error);

      if (error.response) {
        setError(
          `Error fetching website: ${
            error.response.data.error || error.response.statusText
          }`
        );
      } else if (error.request) {
        setError("No response received from the server. Please try again.");
      } else {
        setError(`Error setting up request: ${error.message}`);
      }
    }
  };

  // Function to dynamically add the master script and initialize the chatbot
  const initializeChatbot = (websitePath, websiteUrl) => {
    // 1. Create and append the master script
    const masterScript = document.createElement("script");
    masterScript.src = `${ChatScriptsURL}/assets/js/master.js`;
    masterScript.async = true; // Ensure asynchronous loading
    masterScript.onerror = () => {
      setError("Error loading master chatbot script.");
    };
    document.head.appendChild(masterScript);

    // 2. Initialize the chatbot after the master script loads
    masterScript.onload = () => {
      if (window.CloneMeChatbot) {
        window.CloneMeChatbot.init({
          website_path: websitePath,
          website_url: websiteUrl,
        });
      } else {
        setError("CloneMeChatbot object not found. Initialization failed.");
      }
    };
  };

  return (
    <div>
      <div className="container-fluid p-0">
        {/* You can add any other UI elements here, but not the script display */}

        <div
          id="getError"
          className={`alert alert-warning ${error ? "" : "d-none"}`}
        >
          {error}
        </div>
      </div>
    </div>
  );
}