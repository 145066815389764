import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDistanceToNow, parseISO } from 'date-fns';
import backendURL from "../config";

export default function ChatHistory() {
    const [activeChat, setActiveChat] = useState("");
    const [documents, setDocuments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [chatsPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = 'Chat History | KlonIT Web Application';
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const headers = {
                Authorization: `Bearer ${token}`
            };

            const response = await axios.get(`${backendURL}/get-chat-history`, { headers });
            setDocuments(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError("Authentication error:", error.response.data.error);
            } else {
                setError("Error fetching data:", error);
            }
        }
    };

    const handleChatClick = (chatId) => {
        setActiveChat(chatId);
    };

    const formatRelativeDate = (dateString) => {
        if (dateString === 'N/A' || !dateString) {
            return 'Invalid date';
        }
        const date = parseISO(dateString); // Use parseISO for consistent parsing
        if (isNaN(date)) {
            return 'Invalid date';
        }
        return formatDistanceToNow(date, { addSuffix: true }).replace('about ', ''); // Remove "about"
    };

    // Sort chat IDs in descending order
    const sortedChatIds = Object.keys(documents).sort((a, b) => {
        const dateA = documents[a][0]?.created_date ? parseISO(documents[a][0].created_date) : new Date(0);
        const dateB = documents[b][0]?.created_date ? parseISO(documents[b][0].created_date) : new Date(0);
        return dateB - dateA;
    });

    // Pagination logic
    const totalChats = sortedChatIds.length;
    const indexOfLastChat = currentPage * chatsPerPage;
    const indexOfFirstChat = indexOfLastChat - chatsPerPage;
    const currentChats = sortedChatIds.slice(indexOfFirstChat, indexOfLastChat);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle"> <strong>Chat</strong> History</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="page-chat-history">
                                        <section className="section-chat-history">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <ul className="list-unstyled mb-0">
                                                                {currentChats.map(chatId => (
                                                                    <li key={chatId} className={`p-2 border-bottom chat-bg ${activeChat === chatId ? "active" : ""}`}>
                                                                        <a href="#!" className="d-flex justify-content-between text-decoration-none" aria-controls={chatId} onClick={() => handleChatClick(chatId)}>
                                                                            <div className="d-flex flex-row">
                                                                                <img src="images/avatars/avatar.jpg" alt="avatar" className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-2">
                                                                                    <p className="fw-bold mb-0">{chatId}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-2">
                                                                                <p className="small text-muted mb-1">{formatRelativeDate(documents[chatId][0].created_date)}</p>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                            {/* Pagination */}
                                                            <nav>
                                                                <ul className="pagination justify-content-center mt-3 pagination-display-ruby">
                                                                    {Array.from({ length: Math.ceil(totalChats / chatsPerPage) }, (_, index) => (
                                                                        <li key={index + 1} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                                                            <button className="page-link" onClick={() => paginate(index + 1)}>
                                                                                {index + 1}
                                                                            </button>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </nav>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-7 col-xl-8">
                                                    {activeChat && (
                                                        <div id={activeChat} className="tab-detailed active">
                                                            <ul className="list-unstyled">
                                                                {documents[activeChat]
                                                                    .slice()
                                                                    .sort((a, b) => new Date(b.created_date) - new Date(a.created_date))
                                                                    .map((message, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <li className="d-flex justify-content-between mb-4">
                                                                                <div className="card w-100">
                                                                                    <div className="card-body">
                                                                                        <p className="mb-0">{message.questions}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <img src="images/avatars/avatar.jpg" alt="avatar" className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="40" />
                                                                            </li>
                                                                            <li className="d-flex justify-content-between mb-4">
                                                                                <img src="images/avatars/avatar_img (1).png" alt="avatar" className="rounded-circle d-flex align-self-start me-3 shadow-1-strong" width="40" />
                                                                                <div className="card">
                                                                                    <div className="card-body">
                                                                                        <p className="mb-0">{message.answers}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </React.Fragment>
                                                                    ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}