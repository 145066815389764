import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import backendURL from "../../config";
import EditMonetize from "./EditMonetize";
import AddMonetize from "./AddMonetize";

Modal.setAppElement("#root");

export default function ListMonetize({ refresh }) {
  const [monetizeData, setMonetizeData] = useState([]);
  const [filteredMonetizeData, setFilteredMonetizeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State for AddMonetize modal
  const [currentMonetize, setCurrentMonetize] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    document.title = "Monetize Data | KlonIT Web Application";
    fetchMonetizeData();
  }, [refresh, triggerFetch]);

  useEffect(() => {
    const filtered = monetizeData.filter((data) =>
      Object.values(data)
        .join(" ")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredMonetizeData(filtered);
  }, [searchText, monetizeData]);

  const fetchMonetizeData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendURL}/get-monetizes`, { headers });
      setMonetizeData(response.data);
      setFilteredMonetizeData(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching advertisement data:", error);
        setError("Error fetching advertisement data:", error);
      }
    }
  };

  const handleDelete = async (dataId) => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${backendURL}/delete-monetize/${dataId}`, { headers });
      setMonetizeData(monetizeData.filter((data) => data.id !== dataId));
      setError("Monetize data has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Error deleting data:", error);
    }
  };

  const handleEdit = (data) => {
    setCurrentMonetize(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAddModalOpen(false); // Close AddMonetize modal
    setCurrentMonetize(null);
    setTriggerFetch(!triggerFetch);
  };

  const openAddModal = () => {
    setIsAddModalOpen(true); // Open AddMonetize modal
  };

  const columns = [
    { name: "Title", selector: (row) => row.title, sortable: true, maxWidth: "300px" },
    { name: "Content", selector: (row) => row.page_content, sortable: true, maxWidth: "300px" },
    { name: "URL", selector: (row) => row.page_url, sortable: true, maxWidth: "300px" },
    { name: "Featured", selector: (row) => (row.is_default === true ? "Yes" : "No"), sortable: true, maxWidth: "30px" },
    { name: "Days", selector: (row) => row.days, sortable: true, maxWidth: "30px" },
    { name: "Expire Date", selector: (row) => row.expired_date, sortable: true, maxWidth: "250px" },
    { name: "Upload Date", selector: (row) => row.upload_date, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
            <i className="fa fa-trash-o fa-2x"></i>
          </button>
          <button className="EditIcon" onClick={() => handleEdit(row)}>
            <i className="fa fa-pencil fa-2x"></i>
          </button>
        </>
      ),
    },
  ];

  const EditModalStyle = {
    content: {
      width: "60%",
      height: "75%",
      margin: "auto",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
            <div className="row">
              <div className="col-lg-4"></div>
              <div className="col-lg-4">
                <div className="mt-1" style={{ textAlign: "end" }}>
                  <button className="btn btn-primary" onClick={openAddModal}>
                    <i className="fa fa-plus mx-2"></i>
                    Add Advertisement
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  placeholder="Search data..."
                  className="form-control mb-3"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : filteredMonetizeData.length === 0 ? (
              <p>No data available</p>
            ) : (
              <DataTable
                columns={columns}
                data={filteredMonetizeData}
                pagination
                responsive
                highlightOnHover
                pointerOnHover
              />
            )}
            <br />
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>
              {error}
            </div>
            <br />
          </div>
        </div>
      </div>

      {/* Edit Monetize Modal */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Edit Monetize Data" style={EditModalStyle}>
        <EditMonetize monetize={currentMonetize} onClose={closeModal} />
      </Modal>

      {/* Add Monetize Modal */}
      <Modal isOpen={isAddModalOpen} onRequestClose={closeModal} contentLabel="Add Monetize Data" style={EditModalStyle}>
        <AddMonetize onClose={closeModal} />
      </Modal>
    </div>
  );
}
