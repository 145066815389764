import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import backendURL from "../config";

export default function AvatarSettings() {
    useEffect(() => {
      document.title = 'Avatar Setting | KlonIT Web Application';
    }, []);
  
    const [avatarNames, setAvatarNames] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(null);
    const [errorDiv, setError] = useState(null);
    const isInitialLoad = useRef(true);
  
    // Get the token from localStorage
    const token = localStorage.getItem("access_token");
  
    // Set the headers with the token
    const headers = {
      Authorization: `Bearer ${token}`
    };
  
    useEffect(() => {
      axios.get(`${backendURL}/get_avatar_configurations`, { headers })
        .then(response => {
          const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
          if (Array.isArray(responseData)) {
            const avatarNames = responseData.map(config => config.avatar_name);
            console.log('Avatar names:', avatarNames);
            setAvatarNames(avatarNames);
              isInitialLoad.current = false;
          } else {
            console.error('Invalid response format:', responseData);
          }
        })
        .catch(error => {
          console.error('Error fetching avatar configurations:', error);
        });
    }, []);
  
    const handleAvatarSelection = (avatarIndex) => {
      setSelectedAvatar(avatarIndex);
    };
  
  
    const handleAvatarVisibility = (index) => {
      // If an avatar is selected by the user, toggle visibility
      if (selectedAvatar !== null) {
        return selectedAvatar === index ? 'visible' : 'hidden';
      }
    
      // Initial state - check if the avatar exists in avatarNames
      const imagePath = `images/avatars/avatar_img (${index + 1}).png`;
      const simpleName = `avatar_img (${index + 1})`;
    
      return avatarNames.includes(imagePath) || avatarNames.includes(simpleName) 
        ? 'visible' 
        : 'hidden';
    };
  
    const handleSaveButtonClick = () => {
      if (selectedAvatar !== null) {
        const imagePath = `images/avatars/avatar_img (${selectedAvatar + 1}).png`;
  
        const data = {
          avatar_name: imagePath
        };
  
        console.log("imagePath: ", data);
  
        axios.post(`${backendURL}/update_avatar_configuration`, data, { headers })
          .then(response => {
            console.log('Avatar image path sent successfully:', data);
            setError(response.data.message);
          })
          .catch(error => {
            console.error('Error sending avatar image path:', error);
          });
      } else {
        console.error('No avatar selected');
      }
    };
  
    return (
      <div>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="mb-3">
              <h1 className="h3 d-inline align-middle"> <strong>Avatar</strong> Setting</h1>
            </div>
            <div className="row">
              <div className="col-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title mb-0">Choose your avatar for your website</h5>
                    <br/>
                    <div id="select_avatar" className="row">
                      {[1, 2, 3, 4, 5, 6].map((_, index) => (
                        <div className="col-6 col-md-3" key={index}>
                         <div
                            className={`card avatar_selection
                                  ${selectedAvatar === index ? "selected" : ""}
                                 ${handleAvatarVisibility(index)}`}
                            onClick={() => handleAvatarSelection(index)}
                          >
                            <div
                              className={`avatar-icons`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check-circle align-middle me-2"
                              >
                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                              </svg>
                            </div>
                            <img
                              className="card-img-top"
                              src={`images/avatars/avatar_img (${index + 1}).png`}
                              alt={`Avatar Image ${index + 1}`}
                            />
                            <div className="card-header">
                              <h5 className="card-title mb-0">
                                Avatar {index + 1}
                              </h5>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button type="button" className="btn btn-secondary" onClick={handleSaveButtonClick}>Save</button>
                  </div>
                  <br/>
                  <div id="getError" className={`alert alert-warning ${errorDiv ? "" : "d-none"}`}>{errorDiv}</div>
                </div>
              </div>
            </div>
            
          </div>
        </main>
      </div>
    );
  }