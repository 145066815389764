import React, { useState, useEffect, useRef } from 'react';
import Select from "react-select";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import backendURL from "../../config";
import VoiceList from './VoiceList';

const VoiceTranscription = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [stream, setStream] = useState(null);
  const [error, setError] = useState('');
  const [audioChunks, setAudioChunks] = useState([]);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [languages, setLanguages] = useState([]);
  
  // Refs for cleanup and control
  const recordingTimeout = useRef(null);
  const audioChunksRef = useRef([]);
  const resultIdRef = useRef(null);

  useEffect(() => {
    document.title = "Audio Recording | KlonIT Web Application";
    fetchLanguages();
    
    // Cleanup function
    return () => {
      if (recordingTimeout.current) {
        clearTimeout(recordingTimeout.current);
      }
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const fetchLanguages = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(`${backendURL}/get-languages`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const uniqueLanguages = [];
      const languageValues = new Set();

      response.data.forEach(lang => {
        if (!languageValues.has(lang.language)) {
          languageValues.add(lang.language);
          uniqueLanguages.push({
            value: lang.language,
            label: `${lang.country} (${lang.language})`
          });
        }
      });

      setLanguages(uniqueLanguages);
      
      if (!selectedLanguage && uniqueLanguages.length > 0) {
        const defaultLang = uniqueLanguages.find(lang => lang.value === 'en-US');
        if (defaultLang) {
          setSelectedLanguage(defaultLang.value);
        }
      }
    } catch (error) {
      console.error("Error fetching languages:", error);
      setError("Failed to fetch languages. Please try again.");
    }
  };

  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
    setError('');
  };

  const startRecording = async () => {
    try {
      setIsRecording(true);
      setError('');
      resultIdRef.current = uuidv4();
      audioChunksRef.current = [];
      setAudioChunks([]);

      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { channelCount: 1 },
        video: false
      });

      const recorder = new MediaRecorder(audioStream, {
        mimeType: 'audio/webm;codecs=opus'
      });

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
          setAudioChunks(prev => [...prev, event.data]);
        }
      };

      recorder.onstop = async () => {
        audioStream.getTracks().forEach(track => track.stop());
        setIsRecording(false);
        setStream(null);
        
        // Only proceed with upload if we have audio chunks
        if (audioChunksRef.current.length > 0) {
          setIsUploading(true);
          await handleFileUpload(audioChunksRef.current, resultIdRef.current);
        }
      };

      setMediaRecorder(recorder);
      setStream(audioStream);
      recorder.start(100);

      // Set timeout for automatic stopping after 12 seconds
      recordingTimeout.current = setTimeout(() => {
        if (recorder.state === "recording") {
          recorder.stop();
        }
      }, 120000); // miliseconds

    } catch (err) {
      console.error('Error starting recording:', err);
      setError(`Failed to start recording: ${err.message}`);
      setIsRecording(false);
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    }
  };

  const stopRecording = () => {
    if (recordingTimeout.current) {
      clearTimeout(recordingTimeout.current);
      recordingTimeout.current = null;
    }

    if (mediaRecorder && mediaRecorder.state === "recording") {
      mediaRecorder.stop();
    }

    if (stream) {
      stream.getTracks().forEach(track => track.stop());
      setStream(null);
    }
  };

  const handleFileUpload = async (chunks, resultId) => {
    if (!chunks || chunks.length === 0) {
      setError("No audio recorded.");
      setIsUploading(false);
      return;
    }

    try {
      const audioBlob = new Blob(chunks, { type: "audio/webm" });
      const formData = new FormData();
      formData.append("file", audioBlob, `recording_${resultId}.webm`);
      formData.append("lang_type", selectedLanguage);

      const token = localStorage.getItem("access_token");
      const response = await axios.post(
        `${backendURL}/upload-voice`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
          }
        }
      );

      setError(response.data.message);
      setRefreshList(prev => !prev);
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || "Upload failed";
      setError(`Error uploading audio: ${errorMessage}`);
    } finally {
      setIsUploading(false);
      setAudioChunks([]);
      audioChunksRef.current = [];
    }
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-2">Audio Transcription</h5>
                  <div className="row">
                    <div className="col-6 col-lg-6">
                      <Select
                        className="form-control"
                        options={languages}
                        onChange={handleLanguageChange}
                        value={languages.find(option => option.value === selectedLanguage)}
                        isDisabled={isRecording || isUploading}
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-lg-12">
                      <button
                        className="btn btn-primary mx-2"
                        onClick={isRecording ? stopRecording : startRecording}
                        disabled={isUploading}
                      >
                        {isRecording ? (
                          <>
                            <i className="fa fa-stop-circle me-2"></i> Stop
                          </>
                        ) : (
                          <>
                            <i className="fa fa-microphone me-2"></i> Start
                          </>
                        )}
                      </button>
                      {isUploading && (
                        <span className="ms-3">
                          <i className="fa fa-spinner fa-spin me-2"></i> Transcribing...
                        </span>
                      )}
                    </div>
                  </div>
                  {error && (
                    <div className="row mt-3">
                      <div className="col-12">
                        <div className="alert alert-warning">{error}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <VoiceList refresh={refreshList} />
      </main>
    </div>
  );
};

export default VoiceTranscription;