import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactModal from "react-modal";
import backendURL from "../../config";

ReactModal.setAppElement('#root'); // Add this line to avoid screen reader accessibility warnings

export default function ListSitemap() {
  useEffect(() => {
    document.title = 'Sitemap Lists | KlonIT Web Application';
    fetchDocuments();
  }, []);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [fetchingText, setFetchingText] = useState("");
  const [fetchingIcon, setFetchingIcon] = useState("");
  const [error, setError] = useState(null);
  

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token'); // Assuming you're storing the token in localStorage
      // Set the headers with the token
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.get(`${backendURL}/get-sitemap`, { headers });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle authentication errors, e.g., redirect to login page
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching Sitemap:", error);
        setError("Error fetching Sitemap:", error);
      }
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      await axios.delete(`${backendURL}/delete-sitemap/${documentId}`, { headers });
      // Remove the deleted document from the local state
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setError("Sitemap has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting Sitemap:", error);
      setError("Error deleting Sitemap:", error);
    }
  };

  

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
          </div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <table id="getDocuments" className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Sitemaps</th>
                    <th className="d-none d-xl-table-cell">Create Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document, index) => (
                    <tr key={index}>
                      <td>{document.sitemap}</td>
                      <td className="d-none d-xl-table-cell">{document.created_date}</td>
                      <td className="form-btn-space">
                        <button className="deleteIcon" onClick={() => handleDelete(document.id)}>
                          <i className="fa fa-trash-o fa-2x"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <br/>
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}
            <br/>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={fetching}
        onRequestClose={() => setFetching(false)}
        contentLabel="Fetching Data"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="popup-content">
          <i className={fetchingIcon}></i>
          <p>{fetchingText}</p>
        </div>
      </ReactModal>
    </div>
  );
}
