import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import backendURL from "../../config";

export default function CreatePrompts({ onGeneratePrompt }) {
  const [professions, setProfessions] = useState([]);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [selectedProfession, setSelectedProfession] = useState(null);
  const [specialityOptions, setSpecialityOptions] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [subSkillOptions, setSubSkillOptions] = useState([]);
  const [selectedSubSkills, setSelectedSubSkills] = useState([]);
  const [newSubSkill, setNewSubSkill] = useState(""); // For adding new sub-skills
  const [error, setError] = useState(null);
  const [generatedPrompt, setGeneratedPrompt] = useState("");

  useEffect(() => {
    document.title = "Add Profession | KlonIT Web Application";
    fetchProfessions();
  }, []);

  const fetchProfessions = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`${backendURL}/get-professions`, {
        headers,
      });

      console.log("Get Professions: ", response.data);

      if (response.data && Array.isArray(response.data[0])) {
        const data = response.data[0];
        setProfessions(data);

        const uniqueProfessions = [
          ...new Map(data.map((item) => [item.profession_name, item])).values(),
        ];
        const options = uniqueProfessions.map((item) => ({
          value: item.profession_name,
          label: item.profession_name,
        }));
        setProfessionOptions(options);
      } else {
        setError("Unexpected response structure.");
      }
    } catch (error) {
      console.error("Error fetching professions:", error);
      setError("Error fetching professions.");
    }
  };

  const handleProfessionChange = (selectedOption) => {
    setSelectedProfession(selectedOption);
    setSelectedSpeciality(null);
    setSelectedSubSkills([]);
    const filteredSpecialities = professions.filter(
      (item) => item.profession_name === selectedOption.value
    );
    const specialityOptions = [
      ...new Map(
        filteredSpecialities.map((item) => [item.speciality, item])
      ).values(),
    ].map((item) => ({
      value: item.speciality,
      label: item.speciality,
    }));
    setSpecialityOptions(specialityOptions);
  };

  const handleSpecialityChange = (selectedOption) => {
    setSelectedSpeciality(selectedOption);
    setSelectedSubSkills([]);
    const filteredSubSkills = professions.filter(
      (item) =>
        item.profession_name === selectedProfession.value &&
        item.speciality === selectedOption.value
    );
    const subSkillOptions = [
      ...new Map(filteredSubSkills.map((item) => [item.sub_skills, item])).values(),
    ].map((item) => ({
      value: item.sub_skills,
      label: item.sub_skills,
    }));
    setSubSkillOptions(subSkillOptions);
  };

  const handleSubSkillCheck = (subSkill) => {
    setSelectedSubSkills((prev) =>
      prev.includes(subSkill)
        ? prev.filter((skill) => skill !== subSkill)
        : [...prev, subSkill]
    );
  };

  const handleAddSubSkill = () => {
    if (newSubSkill.trim()) {
      const newSkill = { value: newSubSkill.trim(), label: newSubSkill.trim() };
      setSubSkillOptions((prev) => [...prev, newSkill]);
      setSelectedSubSkills((prev) => [...prev, newSubSkill.trim()]);
      setNewSubSkill("");
    }
  };

  const handleGeneratePrompt = async () => {
    try {
      const companyName = document.getElementById("CompanyName").value || "CompanyName";
      const userPhone = document.getElementById("UserPhone").value || "UserPhone";
      const userEmail = document.getElementById("UserEmail").value || "UserEmail";
      const UserProfession = selectedProfession?.label || "Profession";
  
      const prompt = `You are a ${selectedSpeciality?.label} associated with ${companyName} whose expertise lies in the areas of ${selectedSubSkills.join(
        ", "
      )}. Your answers must strictly adhere to the teachings of ${selectedSubSkills.join(
        ", "
      )}. You are authorized to respond to questions related to ${UserProfession}.
      
      Give a very concise answer initially and offer an option to elaborate where applicable. If the user requests more information, provide that additional detail.
      **If a user's question lacks necessary details for a clear and accurate answer within your domain, you must first ask a clarifying question to obtain the missing information. Once you have all the necessary information, provide a response grounded in ${selectedSubSkills.join(
        ", "
      )}. Do not assume details if they are not explicitly provided.**
      
      If a user's question is outside of your domain, respond with: ‘I’m a virtual scholar whose expertise lies in the area of ${selectedSubSkills.join(
        ", "
      )}. I’m not authorized to answer outside my domain, you may reach out directly at this ${userEmail} or ${userPhone} for further assistance.'`;
      
  
      setGeneratedPrompt(prompt);
  
      const token = localStorage.getItem("access_token");
      const headers = { Authorization: `Bearer ${token}` };
  
      // API call to update prompt settings
      const response = await axios.post(
        `${backendURL}/update-prompt-setting`,
        { prompt },
        { headers }
      );
  
      setError(response.data?.message);
      if (response.status === 200 || response.status === 201) {
        if (onGeneratePrompt) {
          onGeneratePrompt(); // Call the callback to refresh data
        }
      }
    } catch (error) {
      console.error("Error updating prompt:", error);
      setError("Error updating prompt. Please try again.");
    }
  };
  
  

  return (
    <div>
      <div className="container-fluid p-0">
        <div className="mb-3">
          <h1 className="h3 d-inline align-middle">
            <strong>Generate Prompt</strong>
          </h1>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-lg-12 mb-3">
                    <h5 className="card-title mb-1">Company Name</h5>
                    <input id="CompanyName" className="form-control" type="text" />
                  </div>
                  <div className="col-12 col-lg-12 mb-3">
                    <h5 className="card-title mb-1">Profession Name</h5>
                    <Select
                      options={professionOptions}
                      value={selectedProfession}
                      onChange={handleProfessionChange}
                    />
                  </div>
                  <div className="col-12 col-lg-12 mb-3">
                    <h5 className="card-title mb-1">Speciality</h5>
                    <Select
                      options={specialityOptions}
                      value={selectedSpeciality}
                      onChange={handleSpecialityChange}
                      isDisabled={!selectedProfession}
                    />
                  </div>
                  <div className="col-12 col-lg-12 mb-3">
                    <h5 className="card-title mb-1">Sub Skills</h5>
                    <div className="mb-2">
                      {subSkillOptions.map((subSkill, index) => (
                        <div key={index} className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={subSkill.value}
                            checked={selectedSubSkills.includes(subSkill.value)}
                            onChange={() => handleSubSkillCheck(subSkill.value)}
                          />
                          <label className="form-check-label">{subSkill.label}</label>
                        </div>
                      ))}
                      <div className="d-flex mt-3">
                        <input
                          className="form-control me-2"
                          type="text"
                          value={newSubSkill}
                          onChange={(e) => setNewSubSkill(e.target.value)}
                          placeholder="Add new sub-skill"
                        />
                        <Tooltip title="Add new sub skill" arrow>
                          <button
                            className="btn btn-primary"
                            onClick={handleAddSubSkill}
                            style={{ height: "46px" }}
                          >
                            +
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-12 mb-3">
                    <h5 className="card-title mb-1">Phone: </h5>
                    <input id="UserPhone" className="form-control" type="text" />
                  </div>

                  <div className="col-12 col-lg-12 mb-3">
                    <h5 className="card-title mb-1">Email: </h5>
                    <input id="UserEmail" className="form-control" type="text" />
                  </div>

                </div>
                <div className="row mt-3">
                  <div className="col-12 col-lg-12">
                    <button
                      className="btn btn-primary"
                      onClick={handleGeneratePrompt}
                      disabled={
                        !selectedProfession ||
                        !selectedSpeciality ||
                        !selectedSubSkills.length
                      }
                    >
                      Generate Prompt
                    </button>
                    <div className="mt-3">
                      <strong>Generated Prompt:</strong>
                      <p>{generatedPrompt}</p>
                    </div>
                    <div
                      id="getError"
                      className={`alert alert-warning ${error ? "" : "d-none"}`}
                    >
                      {error}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
