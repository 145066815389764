import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../config";
import { saveAs } from 'file-saver';
import ChatbotScripts from './Chatbot/ChatbotScripts';
import RetrainButton from "./Chatbot/RetrainButton"
import UsedTokensChart from "./Chatbot/UsedTokenByDate";
import StatsBox from "./Chatbot/StatsBox";
import ChatbotInterface from "./Chatbot/ChatbotInterface";
import ChatbotStatsTable from "./Chatbot/StatsTable";

export default function RetrainChatbot() {
	useEffect(() => {
		document.title = 'Retrain Chatbot | KlonIT Web Application';
		fetchDocuments();
	}, []);

	const [error, setError] = useState(null);
	const [totalPageCharactersPrivateDocs, setTotalPageCharactersPrivateDocs] = useState(0);
	const [totalPageWordsPrivateDocs, setTotalPageWordsPrivateDocs] = useState(0);
	const [totalTokenPrivateDocs, setTotalTokensPrivateDocs] = useState(0);
	const [totalPrivateDocs, setTotalPrivateDocs] = useState(0);
	const [totalPageCharactersWebsiteLinks, setTotalPageCharactersWebsiteLinks] = useState(0);
	const [totalPageWordsWebsiteLinks, setTotalPageWordsWebsiteLinks] = useState(0);
	const [totalTokensWebsiteLinks, setTotalTokensWebsiteLinks] = useState(0);
	const [totalWebsiteLinks, setTotalWebsiteLinks] = useState(0);
	const [totalSitemap, setTotalSitemap] = useState(0);
	const [totalPlus, setTotalPlus] = useState(0);
	const [loading, setLoading] = useState(false);
	const [buttonText, setButtonText] = useState("Retrain Chatbot");
	const [scriptFilename, setScriptFilename] = useState("myScript.js");

	const [totalInputTokens, setTotalInputTokens] = useState(0);
	const [totalOutputTokens, setTotalOutputTokens] = useState(0);
	const [totalTotalTokens, setTotalTotalTokens] = useState(0);

	const handleRetrainBtnClick = async () => {
		setLoading(true);
		setButtonText("Training...");

		try {
			const token = localStorage.getItem('access_token');
			const headers = {
				Authorization: `Bearer ${token}`
			};

			// Send request to retrain chatbot
			const response = await axios.get(`${backendURL}/retrain-chatbot`, { headers });

			setButtonText("Chatbot Trained");
			setError("You have successfully retrained the chatbot.");
		} catch (error) {
			console.error("Error in retraining the chatbot:", error);
			setButtonText("Retrain Chatbot");
			setError("Error in retraining the chatbot.");
		} finally {
			setLoading(false);
		}
	};

	// Function to fetch documents from the API
	const fetchDocuments = async () => {
		try {
			const token = localStorage.getItem('access_token');
			const headers = {
				Authorization: `Bearer ${token}`
			};

			const response = await axios.get(`${backendURL}/get-retrain-summary`, { headers });

			// Extract values and set state
			setTotalPageCharactersPrivateDocs(response.data.private_documents.total_page_characters);
			setTotalPageWordsPrivateDocs(response.data.private_documents.total_page_words);
			setTotalTokensPrivateDocs(response.data.private_documents.total_tokens);
			setTotalPrivateDocs(response.data.private_documents.total_documents);

			setTotalPageCharactersWebsiteLinks(response.data.website_links.total_page_characters);
			setTotalPageWordsWebsiteLinks(response.data.website_links.total_page_words);
			setTotalTokensWebsiteLinks(response.data.website_links.total_tokens);
			setTotalWebsiteLinks(response.data.website_links.total_links);

			setTotalPlus(response.data.private_documents.total_page_characters + response.data.website_links.total_page_characters);

			setTotalSitemap(response.data.site_maps.total_sitemaps);

			const tokens = response.data.tokens;
			setTotalInputTokens(tokens.total_input_tokens);
			setTotalOutputTokens(tokens.total_output_tokens);
			setTotalTotalTokens(tokens.total_total_token);

			console.log("Received Data: ", response.data)


		} catch (error) {
			if (error.response && error.response.status === 401) {
				console.error("Authentication error:", error.response.data.error);
				setError("Authentication error:", error.response.data.error);
			} else {
				console.error("Error fetching documents:", error);
				setError("Error fetching documents:", error);
			}
		}
	};


	return (
		<div>
			<main className="content">
				<div className="container-fluid p-0">

					<RetrainButton />

					<div className="row mb-2 mb-xl-3">
						<div className="col-auto d-none d-sm-block">
							<h3><strong>Retrain</strong> Chatbot</h3>
						</div>
					</div>
					
					<StatsBox/>

					<div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}

					{/* New Script Section */}
					<div className="row mt-4">
						<div className="col-md-6">
							<ChatbotScripts />
						</div>
						<div className="col-md-6">
							<ChatbotStatsTable/>

						</div>
					</div>
					
					<UsedTokensChart />

				</div>
			</main>
		</div>
	);
}
