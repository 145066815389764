import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress, Alert, Button, ButtonGroup } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import backendURL from "../../config";

// Register components
Chart.register(...registerables);

export default function UsedTokensChart() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [viewMode, setViewMode] = useState("daily"); // Tracks view mode: "daily", "monthly", "yearly"
  const [processedData, setProcessedData] = useState(null); // Stores the raw processed data

  useEffect(() => {
    document.title = "Used Tokens Chart | KlonIT Web Application";
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-used-token-by-date`, { headers });
      processChartData(response.data);
      console.log("Data: ", response.data);
    } catch (error) {
      console.error("Error fetching token data:", error);
      setError("Failed to fetch token data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const processChartData = (data) => {
    const dailyData = data;

    const monthlyData = {};
    const yearlyData = {};

    // Aggregate monthly and yearly data
    data.forEach((item) => {
      const [year, month] = item.created_date.split("-"); // Assuming "YYYY-MM-DD" format
      const yearMonthKey = `${year}-${month}`;

      // Aggregate monthly data
      if (!monthlyData[yearMonthKey]) {
        monthlyData[yearMonthKey] = { input: 0, output: 0, total: 0 };
      }
      monthlyData[yearMonthKey].input += item.input_token;
      monthlyData[yearMonthKey].output += item.output_token;
      monthlyData[yearMonthKey].total += item.total_token;

      // Aggregate yearly data
      if (!yearlyData[year]) {
        yearlyData[year] = { input: 0, output: 0, total: 0 };
      }
      yearlyData[year].input += item.input_token;
      yearlyData[year].output += item.output_token;
      yearlyData[year].total += item.total_token;
    });

    setProcessedData({
      daily: dailyData,
      monthly: monthlyData,
      yearly: yearlyData,
    });

    // Default view: daily
    updateChartData("daily", dailyData, null);
  };

  const updateChartData = (mode, data, labels = null) => {
    let chartLabels, inputTokens, outputTokens, totalTokens;

    if (mode === "daily") {
      chartLabels = data.map((item) => item.created_date);
      inputTokens = data.map((item) => item.input_token);
      outputTokens = data.map((item) => item.output_token);
      totalTokens = data.map((item) => item.total_token);
    } else {
      const aggregatedData = Object.entries(data);
      chartLabels = aggregatedData.map(([key]) => key);
      inputTokens = aggregatedData.map(([_, value]) => value.input);
      outputTokens = aggregatedData.map(([_, value]) => value.output);
      totalTokens = aggregatedData.map(([_, value]) => value.total);
    }

    setChartData({
      labels: chartLabels,
      datasets: [
        {
          label: "Input Tokens",
          data: inputTokens,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
        {
          label: "Output Tokens",
          data: outputTokens,
          backgroundColor: "rgba(255, 159, 64, 0.6)",
        },
        {
          label: "Total Tokens",
          data: totalTokens,
          backgroundColor: "rgba(153, 102, 255, 0.6)",
        },
      ],
    });
  };

  const handleViewChange = (mode) => {
    setViewMode(mode);
    if (processedData) {
      if (mode === "daily") {
        updateChartData(mode, processedData.daily);
      } else if (mode === "monthly") {
        updateChartData(mode, processedData.monthly);
      } else if (mode === "yearly") {
        updateChartData(mode, processedData.yearly);
      }
    }
  };

  const options = {
    plugins: {
      legend: { display: true },
    },
    responsive: true,
    scales: {
      x: { stacked: true },
      y: { stacked: true },
    },
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-body">
            <Box sx={{ p: 4 }}>
              <Typography variant="h4" gutterBottom>
                Used Tokens
              </Typography>
              <ButtonGroup variant="contained" sx={{ mb: 2 }}>
                <Button
                  onClick={() => handleViewChange("daily")}
                  color={viewMode === "daily" ? "primary" : "inherit"}
                >
                  Daily
                </Button>
                <Button
                  onClick={() => handleViewChange("monthly")}
                  color={viewMode === "monthly" ? "primary" : "inherit"}
                >
                  Monthly
                </Button>
                <Button
                  onClick={() => handleViewChange("yearly")}
                  color={viewMode === "yearly" ? "primary" : "inherit"}
                >
                  Yearly
                </Button>
              </ButtonGroup>
              {loading && <CircularProgress />}
              {error && <Alert severity="error">{error}</Alert>}
              {chartData && (
                <Box sx={{ mt: 4 }}>
                  <Bar data={chartData} options={options} />
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}
