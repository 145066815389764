import { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function useChatbotStats() {
    const [stats, setStats] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                const token = localStorage.getItem('access_token');
                const headers = { Authorization: `Bearer ${token}` };
                const response = await axios.get(`${backendURL}/get-retrain-summary`, { headers });
                console.log("Data response: ", response)

                setStats({
                    privateDocs: response.data.private_documents,
                    websiteLinks: response.data.website_links,
                    siteMaps: response.data.site_maps || { total_page_characters: 0, total_page_words: 0, total_tokens: 0 }, // Fallback for missing siteMaps
                    tokens: response.data.tokens,
                    totalCharacters: response.data.private_documents.total_page_characters +
                                     response.data.website_links.total_page_characters,
                    totalWords: response.data.private_documents.total_page_words +
                                response.data.website_links.total_page_words,
                    totalTokens: response.data.private_documents.total_tokens +
                                 response.data.website_links.total_tokens, // Aggregated total tokens
                });
                
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    setError("Authentication error. Please log in again.");
                } else {
                    setError("Failed to fetch stats.");
                }
            }
        };

        fetchStats();
    }, []);

    return { stats, error };
}
