import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function EditMonetize({ monetize, onClose }) {
  const [formData, setFormData] = useState({
    id: monetize?.id || null, // include id in form data
    title: monetize?.title || "",
    days: monetize?.days || 7,
    page_content: monetize?.page_content || "",
    page_url: monetize?.page_url || "",
    is_default: monetize?.is_default || false,
  });

  const [error, setError] = useState(null);

  useEffect(() => {
    // Set initial form data from the passed 'monetize' prop
    if (monetize) {
      setFormData({
        id: monetize.id,
        title: monetize.title,
        days: monetize.days,
        page_content: monetize.page_content,
        page_url: monetize.page_url,
        is_default: monetize.is_default,
      });
    }
  }, [monetize]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = async () => {
      try {
          const token = localStorage.getItem("access_token");
          const headers = {
              Authorization: `Bearer ${token}`,
          };

          const response = await axios.post(
              `${backendURL}/update-monetize/${formData.id}`,
              formData,
              { headers }
          );

        if (response.status === 200) {
          console.log("Advertisement data updated successfully");
          onClose(); // Close the modal on success
        }
      } catch (error) {
        console.error("Error updating advertisement data:", error);
        setError("Failed to update the data");
      }
    };

  return (
    <div>
      <h3>Edit Advertisement</h3>
      <form>
        <div className="row">
          <div className="mb-3 col-6 col-lg-6">
            <label htmlFor="title" className="form-label">
              Title:
            </label>
            <input
              type="text"
              id="title"
              name="title"
              className="form-control"
              value={formData.title}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3 col-6 col-lg-6">
            <label htmlFor="days" className="form-label">
              Days:
            </label>
            <select
              id="days"
              name="days"
              className="form-control"
              value={formData.days}
              onChange={handleChange}
              required
            >
              <option value={7}>7</option>
              <option value={15}>15</option>
              <option value={30}>30</option>
            </select>
          </div>
          <div className="mb-3 col-12 col-lg-12">
            <label htmlFor="page_content" className="form-label">
              Advertisement Detail:
            </label>
            <textarea
              id="page_content"
              name="page_content"
              className="form-control"
              rows="5"
              value={formData.page_content}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="mb-3 col-8 col-lg-8">
            <label htmlFor="page_url" className="form-label">
              Advertisement URL:
            </label>
            <input
              type="text"
              id="page_url"
              name="page_url"
              className="form-control"
              value={formData.page_url}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3 form-check col-2 col-lg-2">
            <input
              type="checkbox"
              id="is_default"
              name="is_default"
              className="form-check-input"
              checked={formData.is_default}
              onChange={handleChange}
            />
            <label htmlFor="is_default" className="form-check-label">
              Featured:
            </label>
          </div>
        </div>
      </form>

      <div className="mt-3">
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
        <button className="btn btn-secondary mx-2" onClick={onClose}>
          Cancel
        </button>
      </div>

      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </div>
  );
}