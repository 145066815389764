import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";
import ListSitemap from './ListSitemap';

export default function AddSitemap({ closeModal, refresh, handleRefresh }) {

  useEffect(() => {
    document.title = 'Add Sitemap | KlonIT Web Application';
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [sitemaps, setSitemaps] = useState([]);
  const urlRegex = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/i;
  const [error, setError] = useState(null);
  const [fetchingIcon, setFetchingIcon] = useState("");
  const [fetchingText, setFetchingText] = useState("");

  const handleAddWebBtnClick = async () => {
    if (urlRegex.test(inputValue)) {
      try {
        setFetchingIcon("fa fa-spinner fa-spin");
        setFetchingText("Keep calm, we are fetching...");

        const token = localStorage.getItem('access_token');
        const headers = {
          Authorization: `Bearer ${token}`
        };

        const response = await axios.post(`${backendURL}/add-sitemap`, { sitemap: inputValue }, { headers });

        setSitemaps([...sitemaps, inputValue]);
        setInputValue("");
        setError("Sitemaps has been added successfully.");
        handleRefresh(); // Refresh the list after adding
        closeModal(); // Close the modal after adding
      } catch (error) {
        console.error("Error adding Sitemaps:", error);
        setError("Error adding Sitemaps:", error);
      } finally {
        setFetchingIcon("");
        setFetchingText("");
      }
    } else {
      alert("Please enter a valid Sitemaps.");
    }
  };

  return (
    <div>
      <main className="content" style={{ minHeight: '100%' }}>
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">
              <strong>Add Sitemap</strong> Links
            </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-2">
                    (Link should be started with https:// or www and ends with .xml)
                  </h5>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-lg-12">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddWebBtnClick}
                      >
                        Add Sitemaps
                      </button>
                      {fetchingIcon && (
                        <div className="mt-3">
                          <i className={fetchingIcon} aria-hidden="true"></i> {fetchingText}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <div id="getError" className={`alert alert-warning mt-3 ${error ? "" : "d-none"}`}>
                        {error}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}