import React, { useEffect } from "react";
import useChatbotStats from "./useChatbotStats";

export default function ChatbotStatsTable() {
    useEffect(() => {
        document.title = 'Chatbot Stats Table | KlonIT Web Application';
    }, []);

    const { stats, error } = useChatbotStats();

    if (error) return <div className="alert alert-danger">{error}</div>;
    if (!stats) return <div>Loading...</div>;

    return (
        <div>
            <h4>Chatbot Statistics</h4>
            <div className="card">
                <div className="card-body">
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>Category</th>
                                <th className="text-end">Total Characters</th>
                                <th className="text-end">Total Words</th>
                                <th className="text-end">Total Tokens</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Private Documents</td>
                                <td className="text-end">{stats.privateDocs.total_page_characters}</td>
                                <td className="text-end">{stats.privateDocs.total_page_words}</td>
                                <td className="text-end">{stats.privateDocs.total_tokens}</td>
                            </tr>
                            <tr>
                                <td>Website Links</td>
                                <td className="text-end">{stats.websiteLinks.total_page_characters}</td>
                                <td className="text-end">{stats.websiteLinks.total_page_words}</td>
                                <td className="text-end">{stats.websiteLinks.total_tokens}</td>
                            </tr>
                            <tr>
                                <td>Sitemaps</td>
                                <td className="text-end">{stats.siteMaps?.total_page_characters || "N/A"}</td>
                                <td className="text-end">{stats.siteMaps?.total_page_words || "N/A"}</td>
                                <td className="text-end">{stats.siteMaps?.total_tokens || "N/A"}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td className="text-end">{stats.totalCharacters}</td>
                                <td className="text-end">{stats.totalWords}</td>
                                <td className="text-end">{stats.totalTokens}</td>
                            </tr>
                            <tr>
                                <td>Overall Tokens (Input + Output)</td>
                                <td className="text-end" colSpan={2}></td>
                                <td className="text-end">{stats.tokens.total_total_token}</td>
                            </tr>
                        </tbody>


                    </table>
                </div>
            </div>
        </div>
    );
}
