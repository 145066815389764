import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import backendURL from "../../config";
import ListWebsites from './ListWebsites';
import ListScrapeData from './ListScrapeData';
import ListTrainedData from './ListTrainedData';
import ListDocuments from './ListDocuments';
import AddSitemap from "../Sitemaps/AddSitemap";
import ListSitemap from "../Sitemaps/ListSitemap";
import AddWebsite from "./AddWebsite";
import AddHomePage from "../Sitemaps/AddHomePage";
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

Modal.setAppElement('#root');

export default function Scraper() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Scraper | KlonIT Web Application';
  }, []);

  const [tabValue, setTabValue] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [isSitemapModalOpen, setIsSitemapModalOpen] = useState(false);
  const [isWebsiteModalOpen, setIsWebsiteModalOpen] = useState(false);
  const [isHomePageModalOpen, setIsHomePageModalOpen] = useState(false); // Add state for AddHomePage modal
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleRefresh = useCallback(() => {
    setRefresh(prevRefresh => !prevRefresh);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const openSitemapModal = () => {
    setIsSitemapModalOpen(true);
  };

  const closeSitemapModal = () => {
    setIsSitemapModalOpen(false);
  };

  const openWebsiteModal = () => {
    setIsWebsiteModalOpen(true);
  };

  const closeWebsiteModal = () => {
    setIsWebsiteModalOpen(false);
  };

  const openHomePageModal = () => { // Function to open AddHomePage modal
    setIsHomePageModalOpen(true);
  };

  const closeHomePageModal = () => { // Function to close AddHomePage modal
    setIsHomePageModalOpen(false);
  };

  const openListSitemap = () => {
    setIsDrawerOpen(true);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">
              <strong>Scraper</strong>
            </h1>
          </div>
          <div className="row" style={{ textAlign: 'end' }}>
            <div className="col-4 col-lg-2"></div>
            <div className="col-8 col-lg-10">
              <button className="btn btn-primary mx-2" onClick={openSitemapModal}>
                Add Sitemap
              </button>
              <button className="btn btn-primary mx-2" onClick={openHomePageModal}>
                Add Homepage
              </button>
              <button className="btn btn-primary mx-2" onClick={openWebsiteModal}>
                Add Website Links
              </button>
              <button className="btn btn-primary mx-1" onClick={openListSitemap}>
                List Sitemap
              </button>
            </div>

            {/* Add Sitemap Modal */}
            <Modal
              isOpen={isSitemapModalOpen}
              onRequestClose={closeSitemapModal}
              contentLabel="Add Sitemap Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              <AddSitemap
                closeModal={closeSitemapModal}
                refresh={refresh}
                handleRefresh={handleRefresh}
              />
            </Modal>

            {/* Add HomePage Modal */}
            <Modal
              isOpen={isHomePageModalOpen}
              onRequestClose={closeHomePageModal}
              contentLabel="Add HomePage Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              <AddHomePage
                closeModal={closeHomePageModal}
                refresh={refresh}
                handleRefresh={handleRefresh}
              />
            </Modal>

            {/* Add Website Modal */}
            <Modal
              isOpen={isWebsiteModalOpen}
              onRequestClose={closeWebsiteModal}
              contentLabel="Add Website Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              <AddWebsite
                closeModal={closeWebsiteModal}
                refresh={refresh}
                handleRefresh={handleRefresh}
              />
            </Modal>

            {/* List Sitemap Drawer */}
            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: '50vw', padding: '20px' }}
                role="presentation"
              >
                <ListSitemap 
                  refresh={refresh} 
                  setRefresh={setRefresh} 
                />
              </Box>
            </Drawer>
          </div>
        </div>

        {/* Tabs */}
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="website and scrape data tabs"
          >
            <Tab label="Unscraped Data >" />
            <Tab label="Trained Data" />
          </Tabs>
          <Box sx={{ p: 3 }}>
            {tabValue === 0 && (
              <ListWebsites 
                refresh={refresh} 
                setRefresh={setRefresh} 
              />
            )}
            {tabValue === 1 && (
              <ListTrainedData 
                refresh={refresh} 
                setRefresh={setRefresh} 
              />
            )}
          </Box>
        </Box>
      </main>

      {/* Modal and Overlay Styles */}
      <style jsx>{`
        .Modal {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          padding: 20px;
          border-radius: 5px;
          max-width: 80%;
          max-height: 80%;
          overflow-y: auto;
          width: 50%;
          height: 50%;
        }

        .Overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.75);
          z-index: 1000;
        }
      `}</style>
    </div>
  );
}