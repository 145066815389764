import React, { useState } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function EditVoice({ document, onClose }) {
  const [pageContent, setPageContent] = useState(document?.content || "");
  const [allData, setAllData] = useState(document || "");
  const [error, setError] = useState(null);

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${backendURL}/update-voice`,
        { id: document.id, content: pageContent, all_data: allData },
        { headers }
      );

      if (response.status === 200) {
        console.log("Document updated successfully");
        onClose(); // Close the modal on success
      }
    } catch (error) {
      console.error("Error updating voice data:", error);
      setError("Failed to update the voice data");
    }
  };

  return (
    <div>
      <h3>Edit Document</h3>
      <textarea
        className="form-control"
        value={pageContent}
        onChange={(e) => setPageContent(e.target.value)}
        rows="10"
      />
      <br />
      <button className="btn btn-primary" onClick={handleSave}>
        Save
      </button>
      <button className="btn btn-secondary mx-2" onClick={onClose}>
        Cancel
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </div>
  );
}
