import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import backendURL from "../../config";
import AddLanguages from "./AddLanguages";
import Drawer from "@mui/material/Drawer";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ListAudioLanguage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fetchedUrl = queryParams.get('fetched_url');

  

  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [reload, setReload] = useState(false);

  // Function to toggle the reload state
  const handleReload = () => {
    setReload(!reload); 
  };

  useEffect(() => {
    document.title = "Audio & Language Settings | KlonIT Web Application";
    fetchDocuments();
  }, [reload]);

  // Function to fetch documents from the API
  const fetchDocuments = useCallback(async () => {
      try {
          const token = localStorage.getItem("access_token");
          const headers = {
              Authorization: `Bearer ${token}`,
          };

          const response = await axios.get(`${backendURL}/get-language-audio`, { headers });
          setDocuments(response.data);
          setFilteredDocuments(response.data); // Initialize filtered data
          setLoading(false);
      } catch (error) {
          if (error.response && error.response.status === 401) {
              console.error("Authentication error:", error.response.data.error);
              setError("Authentication error:", error.response.data.error);
          } else {
              console.error("Error fetching data:", error);
              setError("Error fetching data:", error.message);
          }
          setLoading(false);
      }
  }, []); // Add fetchDocuments as a dependency

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      await axios.delete(`${backendURL}/delete-language-audio/${documentId}`, { headers });
      const updatedDocuments = documents.filter((doc) => doc.id !== documentId);
      setDocuments(updatedDocuments);
      setFilteredDocuments(updatedDocuments); // Update filtered data as well
      setError("Data has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Error deleting data:", error.message);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = documents.filter(
      (doc) =>
        doc.website_lang.toLowerCase().includes(value) ||
        doc.website_gender.toLowerCase().includes(value) ||
        doc.created_date.toLowerCase().includes(value)
    );
    setFilteredDocuments(filtered);
  };

  const columns = [
    {
      name: "Language",
      selector: (row) => row.website_lang,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.website_gender,
      sortable: true,
    },
    {
      name: "Default Language",
      selector: (row) => (row.default_language ? "Yes" : "No"),
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
          <i className="fa fa-trash-o fa-2x"></i>
        </button>
      ),
    },
  ];

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
            <div className="row">
              <div className="col-lg-4">
                <h5 className="card-title mb-1">Audio & Language</h5>
              </div>
              <div className="col-lg-4">
                <div className="mt-1" style={{ textAlign: "end" }}>
                  <button className="btn btn-primary" onClick={() => toggleDrawer(true)}>
                    <i className="fa fa-plus mx-2"></i>
                    Add New Language
                  </button>
                </div>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  placeholder="Search data..."
                  className="form-control mb-3"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : filteredDocuments.length === 0 ? (
              <p>No data available</p>
            ) : (
              <DataTable
                columns={columns}
                data={filteredDocuments}
                pagination
                highlightOnHover
                pointerOnHover
              />
            )}
            <br />
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>
              {error}
            </div>
            <br />
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => toggleDrawer(false)}
        PaperProps={{ style: { width: "500px" } }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
          <IconButton onClick={() => toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <AddLanguages onClose={() => toggleDrawer(false)} setReload={handleReload} />
      </Drawer>
    </div>
  );
}