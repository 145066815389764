import React from "react";
import useChatbotStats from "./useChatbotStats";

export default function StatsBox() {
    const { stats, error } = useChatbotStats();

    if (!stats) return <div>Loading...</div>; // Show loading state while stats are being fetched

    return (
        <div>
            <div className="row">
                {/* Private Documents Stats */}
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Number of Files</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <i className="fa fa-file-o"></i>
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3">{stats.privateDocs.total_documents}</h1>
                            <div className="mb-0">
                                <span className="badge badge-success-light">{stats.privateDocs.total_page_characters}</span>
                                <span className="text-muted"> characters, </span>
                                <span className="badge badge-success-light">{stats.privateDocs.total_page_words}</span>
                                <span className="text-muted"> words, and </span>
                                <span className="badge badge-success-light">{stats.tokens.private_tokens}</span>
                                <span className="text-muted"> tokens.</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Website Links Stats */}
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">No. of Website Links</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <i className="fa fa-link"></i>
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3">{stats.websiteLinks.total_links}</h1>
                            <div className="mb-0">
                                <span className="badge badge-danger-light">{stats.websiteLinks.total_page_characters}</span>
                                <span className="text-muted"> characters, </span>
                                <span className="badge badge-danger-light">{stats.websiteLinks.total_page_words}</span>
                                <span className="text-muted"> words, and </span>
                                <span className="badge badge-danger-light">{stats.tokens.website_tokens}</span>
                                <span className="text-muted"> tokens.</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Words */}
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Total Words</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <i className="fa fa-font"></i>
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3">{stats.totalWords}</h1>
                            <div className="mb-0">
                                <span className="text-muted">
                                    Total characters: {stats.totalCharacters}, total tokens: {stats.totalTokens}.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Total Tokens */}
                <div className="col-sm-6 col-xl-3">
                    <div className="card card-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Total Used Tokens</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <i className="fa fa-comment"></i>
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3">{stats.totalTokens}</h1>
                            <div className="mb-0">
                                <span className="text-muted">
                                    Total input tokens: {stats.tokens.input_tokens}, output tokens: {stats.tokens.output_tokens}.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Error Message */}
            {error && (
                <div id="getError" className="alert alert-warning">
                    {error}
                </div>
            )}
        </div>
    );
}
