import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    Avatar,
    Box,
    ListItemText,
    Tooltip
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useTheme } from './../ThemeContext';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ChatbotLive from '../pages/Chatbot/Live';

export default function Header({ toggleSidebar }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [profileImg, setProfileImg] = useState('');
    const open = Boolean(anchorEl);
    const { mode, toggleTheme } = useTheme();

    useEffect(() => {
        // Retrieve data from localStorage
        const storedFname = localStorage.getItem('f_name');
        const storedLname = localStorage.getItem('l_name');
        const storedProfileImg = localStorage.getItem('profile_image');

        // Update state with retrieved data
        if (storedFname) setFname(storedFname);
        if (storedLname) setLname(storedLname);
        if (storedProfileImg) setProfileImg(storedProfileImg);
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <ChatbotLive/>
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={toggleSidebar}
                >
                    <MenuIcon />
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                  <Tooltip title={mode === 'light' ? "Enable Dark Mode" : "Enable Light Mode"}>
                  <IconButton color="inherit" onClick={toggleTheme}>
                        {mode === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
                    </IconButton>
                 </Tooltip>

                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                >
                    {profileImg ? (
                        <Avatar alt={`${fname} ${lname}`} src={profileImg} />
                    ) : (
                        <AccountCircle />
                    )}
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose} component={Link} to="/user-profile" >
                        <ListItemText primary="Profile" sx={{ color: 'black' }} />
                    </MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/Billing">
                        <ListItemText primary="Billing" sx={{ color: 'black' }} />
                    </MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/help">
                        <ListItemText primary="Help Center" sx={{ color: 'black' }} />
                    </MenuItem>
                    <MenuItem onClick={handleClose} component={Link} to="/log-out" >
                        <ListItemText primary="Log out" sx={{ color: 'black' }} />
                    </MenuItem>
                </Menu>
            </Toolbar>
        </AppBar>
    );
}