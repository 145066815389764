import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import backendURL from "../../config";

function AddLanguages({ onClose, setReload }) {
  const [documents, setDocuments] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [DefaultLanguage, setDefaultLanguage] = useState("No");
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = "Audio & Language Settings | KlonIT Web Application";
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (audioSrc) {
      const audio = new Audio(audioSrc);
      setAudioPlayer(audio);
    }
  }, [audioSrc]);

  // Fetch documents on component mount
  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.get(`${backendURL}/get-languages`, { headers });
      setDocuments(response.data);
      console.log("Get lang data: ", response.data);
    } catch (error) {
      handleError(error);
    }
  };

  // Handle API call errors
  const handleError = (error) => {
    if (error.response && error.response.status === 401) {
      setError("Authentication error: " + error.response.data.error);
    } else {
      setError("Error: " + error.message);
    }
  };

  // Prepare language options
  const languageOptions = [
    ...new Set(documents.map((doc) => doc.country)),
  ].map((country) => ({
    value: country,
    label: country,
  }));

  // Filter documents based on selected language and gender
  const filteredDocuments = documents.filter(
    (doc) =>
      (!selectedLanguage || doc.country === selectedLanguage.value) &&
      (!selectedGender || doc.gender === selectedGender)
  );

  // Prepare voice options based on filtered documents
  const voiceOptions = filteredDocuments.map((doc) => ({
    value: doc.voice_name,
    label: doc.name,
  }));

  // Handlers for selection changes
  const handleLanguageChange = (selectedOption) => {
    setSelectedLanguage(selectedOption);
    setSelectedVoice(null); // Reset voice selection when language changes
  };

  const handleGenderButtonClick = (gender) => {
    setSelectedGender(gender);
    setSelectedVoice(null); // Reset voice selection when gender changes
  };

  const handleVoiceChange = (selectedOption) => {
    setSelectedVoice(selectedOption);
    if (selectedOption) {
      const audioFileName = selectedOption.value + ".wav";
      const audioPath = require(`../../assets/audio/${audioFileName}`);
      setAudioSrc(audioPath);
    }
  };

  // Audio playback controls
  const handlePlayButtonClick = () => {
    audioPlayer?.play();
  };

  const handleStopButtonClick = () => {
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
  };

  const handleDefaultLanguage = (DefaultLanguageValue) => {
    setDefaultLanguage(parseInt(DefaultLanguageValue));
  };

  // Handle API call to save settings
  const handleApiCall = async () => {
    // Find the selected document based on selected language and voice
    const selectedDocument = documents.find(
      (doc) =>
        doc.country === selectedLanguage?.value &&
        doc.voice_name === selectedVoice?.value &&
        doc.gender === selectedGender
    );

    if (!selectedDocument) {
      setError("Please select valid language, voice, and gender options.");
      return;
    }

    // Prepare the data object
    const data = {
      language: selectedLanguage?.value,
      website_lang_code: selectedDocument.language,
      voice: selectedVoice?.value,
      gender: selectedGender,
      default_lang: DefaultLanguage,
      lang_code: selectedDocument.lang_code, // Add lang_code
      lang_flag: selectedDocument.lang_flag, // Add lang_flag
    };

    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.post(`${backendURL}/add-language-audio`, data, { headers });
      setError(response.data.message);
      
      // Close the drawer
      onClose();
      // Trigger reload in the parent component
      setReload();

    } catch (error) {
      handleError(error);
    }
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Add z-index here
    }),
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          {/* ... (Rest of your JSX) */}
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <h5 className="card-title mb-1">Select Website Language</h5>
                      <Select
                        id="LanguageSelectionSelect"
                        options={languageOptions}
                        onChange={handleLanguageChange}
                        value={selectedLanguage}
                        styles={customStyles}
                      />
                      <br />

                      <h5 className="card-title mb-1">Select Gender</h5>
                      <div
                        id="selectGender"
                        className="btn-group btn-group-lg mb-3"
                        role="group"
                        aria-label="Large button group"
                      >
                        <button
                          type="button"
                          className={`btn btn-secondary ${selectedGender === "male" ? "active" : ""}`}
                          onClick={() => handleGenderButtonClick("male")}
                        >
                          Male
                        </button>
                        <button
                          type="button"
                          className={`btn btn-secondary ${selectedGender === "female" ? "active" : ""}`}
                          onClick={() => handleGenderButtonClick("female")}
                        >
                          Female
                        </button>
                      </div>
                      <br />

                      <h5 className="card-title mb-1">Select Voice</h5>
                      <div className="row">
                        <div className="col-11 col-lg-11">
                          <Select
                            id="VoiceSelectionSelect"
                            options={voiceOptions}
                            onChange={handleVoiceChange}
                            value={selectedVoice}
                            styles={customStyles}
                          />
                        </div>

                        <div className="col-1 col-lg-1" style={{ paddingLeft: "0px" }}>
                          <button
                            type="button"
                            className="btn btn-secondary btn-play-on"
                            onClick={handlePlayButtonClick}
                          >
                            <i class="fa fa-play"></i>
                          </button>
                        </div>
                      </div>
                      <br />

                      <h5 className="card-title mb-1">Select Default Language</h5>
                      <div
                        id="DefaultLanguageID"
                        className="btn-group btn-group-lg mb-3"
                        role="group"
                        aria-label="Large button group"
                      >
                        <button
                          type="button"
                          className={`btn btn-secondary ${DefaultLanguage === 1 ? "active" : ""}`}
                          onClick={() => handleDefaultLanguage(1)}
                        >
                          Yes
                        </button>
                        <button
                          type="button"
                          className={`btn btn-secondary ${DefaultLanguage === 0 ? "active" : ""}`}
                          onClick={() => handleDefaultLanguage(0)}
                        >
                          No
                        </button>
                      </div>
                      <br />

                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleApiCall}
                      >
                        Add New Language
                      </button>
                    </div>
                  </div>
                  <br />
                  <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default AddLanguages;