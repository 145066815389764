import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable, { defaultThemes } from 'react-data-table-component';
import backendURL from "../../config";
import RetrainButton from "../Chatbot/RetrainButton"
import Modal from "react-modal";
import EditTrainedData from "./EditTrainedData";

export default function ListTrainedData({ refresh }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fetchedUrl = queryParams.get('fetched_url');

  console.log('Query Params:', queryParams.toString());
  console.log('fetchedUrl:', fetchedUrl);

  useEffect(() => {
    document.title = 'Website Lists | KlonIT Web Application';
    fetchDocuments();
  }, [refresh]);

  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token'); // Assuming you're storing the token in localStorage
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-trained-website-links`, { headers });

      // Filter the documents where is_scrape is 0
      const filteredDocuments = response.data.filter(doc => doc.is_scrape === true && doc.is_trained === true);
      console.log("response.data: ", response.data)
      console.log("filteredDocuments: ", filteredDocuments)

      setDocuments(filteredDocuments);
      setFilteredDocuments(filteredDocuments);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching website:", error);
        setError("Error fetching website:", error);
      }
    }
  };

  const columns = [
    {
      name: 'Website Link',
      selector: row => row.website_link,
      sortable: true,
      maxWidth: '315px', // Limit the width of this column
      style: {
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis if text overflows
      },
    },
    {
      name: 'Page Content',
      selector: row => row.page_content,
      maxWidth: '315px', // Limit the width of this column
      style: {
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis if text overflows
      },
    },
    {
      name: 'Is Trained?',
      selector: row => row.is_trained ? "Yes" : "No",
      sortable: true,
      maxWidth: '200px', // Limit the width of this column
      style: {
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis if text overflows
      },
    },
    {
      name: 'Date',
      selector: row => row.created_date,
      maxWidth: '315px', // Limit the width of this column
      style: {
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis if text overflows
      },
    },
    {
      name: 'Action',
      cell: row => (
        <>
          <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
            <i className="fa fa-trash-o fa-2x"></i>
          </button>
          <button className="EditIcon" onClick={() => handleEdit(row)}>
            <i className="fa fa-pencil fa-2x"></i>
          </button>
        </>
      ),
    },
  ];

  const ExpandedRow = ({ data }) => {
    return (
      <div style={{ padding: '10px', lineHeight: '1.5' }}>
        <p><strong>Website Link:</strong> {data.website_link}</p>
        <p><strong>Title Tag:</strong> {data.title_tag}</p>
        <p><strong>Page Content:</strong> {data.page_content}</p>
        <p><strong>Summary:</strong> {data.summary}</p>
        <p><strong>H1 Heading:</strong> {data.h1_tags}</p>
        <p><strong>H2 Heading:</strong> {data.h2_tags}</p>
        <p><strong>H3 Heading:</strong> {data.h3_tags}</p>
      </div>
    );
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`${backendURL}/delete-website-scrape-data/${documentId}`, { headers });
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setFilteredDocuments(filteredDocuments.filter((doc) => doc.id !== documentId));
      setError("Website link has been deleted successfully.");
    } catch (error) {
      setError("Error deleting Website:", error.message);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filteredData = documents.filter((doc) =>
      doc.website_link.toLowerCase().includes(value) ||
      doc.title_tag.toLowerCase().includes(value) ||
      doc.page_content.toLowerCase().includes(value) ||
      (doc.h1_tags && doc.h1_tags.toLowerCase().includes(value)) ||
      (doc.h2_tags && doc.h2_tags.toLowerCase().includes(value)) ||
      (doc.h3_tags && doc.h3_tags.toLowerCase().includes(value))
    );

    setFilteredDocuments(filteredData);
  };

  const handleEdit = (document) => {
    setCurrentDocument(document);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDocument(null);
    fetchDocuments();
  };

  const EditModalStyle = {
    content: {
      width: "50%",
      height: "50%",
      margin: "auto",
      padding: "20px",
      borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">

          <div className="card-body">

            <div className="row">
              <div className="col-lg-8">
                <div className="mt-1">
                  <RetrainButton />
                </div>
              </div>
              <div className="col-lg-4">
                <input
                  type="text"
                  placeholder="Search.."
                  value={searchText}
                  onChange={handleSearch}
                  style={{ marginBottom: "10px", padding: "5px", width: "100%" }}
                />
              </div>
            </div>

            {fetchedUrl && <div className="alert alert-info">Please select URLs to fetch the data.</div>}

            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <div>
                <DataTable
                  columns={columns}
                  data={filteredDocuments}
                  pagination
                  responsive
                  expandableRows
                  expandableRowsComponent={({ data }) => <ExpandedRow data={data} />}
                  expandOnRowClicked
                />

              </div>
            )}
            <br />
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
            <br />
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Edit Images" style={EditModalStyle}>
        <EditTrainedData document={currentDocument} onClose={closeModal} onUpdate={fetchDocuments} />
      </Modal>
    </div>
  );
}
