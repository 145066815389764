import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import ListTrainedData from "../WebsiteLinks/ListTrainedData";
import ListDocuments from "../Documents/ListDocuments";
import ListImages from "../Image/ListImages";
import VoiceList from "../VoiceRecording/VoiceList";
import backendURL from "../../config";

// Expanded Component for expandable rows
const ExpandedComponent = ({ data }) => {
  return (
    <div style={{ padding: "10px" }}>
      {data.type === "Website Data" && (
        <>
          <p>
            <strong>Website Link:</strong> {data.website_link}
          </p>
          <p>
            <strong>Page Content:</strong> {data.page_content}
          </p>
        </>
      )}
      {data.type === "Documents Data" && (
        <>
          <p>
            <strong>Filename:</strong> {data.filename}
          </p>
          <p>
            <strong>Page Content:</strong> {data.page_content}
          </p>
        </>
      )}
      {data.type === "Images Data" && (
        <>
          <p>
            <strong>Filename:</strong> {data.filename}
          </p>
          <p>
            <strong>Image Content:</strong> {data.image_content}
          </p>
        </>
      )}
      {data.type === "Voice Data" && (
        <>
          <p>
            <strong>Filename:</strong> {data.filename}
          </p>
          <p>
            <strong>Content:</strong> {data.content}
          </p>
        </>
      )}
    </div>
  );
};

export default function KnowledgeData({ refresh }) {
  const [trainedData, setTrainedData] = useState([]);
  const [documentsData, setDocumentsData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [voiceData, setVoiceData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("access_token");
        const headers = { Authorization: `Bearer ${token}` };

        const trainedResponse = await axios.get(
          `${backendURL}/get-trained-website-links`,
          { headers }
        );
        const documentsResponse = await axios.get(
          `${backendURL}/get-private-data`,
          { headers }
        );
        const imagesResponse = await axios.get(`${backendURL}/get-images`, {
          headers,
        });
        const voiceResponse = await axios.get(`${backendURL}/get-voices`, {
          headers,
        });

        setTrainedData(trainedResponse.data);
        setDocumentsData(documentsResponse.data);
        setImagesData(imagesResponse.data);
        setVoiceData(voiceResponse.data);

        const combined = [
          ...trainedResponse.data.map((item) => ({
            ...item,
            type: "Website Data",
            Data: item.website_link,
            Content: item.page_content,
            Date: item.created_date,
          })),
          ...documentsResponse.data.map((item) => ({
            ...item,
            type: "Documents Data",
            Data: item.filename,
            Content: item.page_content,
            Date: item.upload_date,
          })),
          ...imagesResponse.data.map((item) => ({
            ...item,
            type: "Images Data",
            Data: item.filename,
            Content: item.image_content,
            Date: item.upload_date,
          })),
          ...voiceResponse.data.map((item) => ({
            ...item,
            type: "Voice Data",
            Data: item.filename,
            Content: item.content,
            Date: item.upload_date,
          })),
        ];
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Network Error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [refresh]);

  const filteredData = combinedData.filter((item) => {
    const searchTextLower = searchText.toLowerCase();
    return (
      (item.type && item.type.toLowerCase().includes(searchTextLower)) ||
      (item.Data && item.Data.toLowerCase().includes(searchTextLower)) ||
      (item.Content && item.Content.toLowerCase().includes(searchTextLower)) ||
      (item.Date && item.Date.toLowerCase().includes(searchTextLower))
    );
  });

  // Function to get background color based on data type
  const getDataTypeColor = (type) => {
    switch (type) {
      case "Website Data":
        return "#8B0000"; // Dark red
      case "Documents Data":
        return "#006400"; // Dark green
      case "Images Data":
        return "#A52A2A"; // Brown
      case "Voice Data":
        return "#556B2F"; // Olive green
      default:
        return "#2F4F4F"; // Dark slate gray
    }
  };

  const columns = [
    
    {
      name: "Data",
      selector: (row) => row.Data,
      sortable: true,
      wrap: true,
      compact: true,
      cell: row => <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.Data}</div>,
    },
    {
      name: "Content",
      selector: (row) => row.Content,
      sortable: true,
      maxWidth: '315px', // Limit the width of this column
      style: {
        whiteSpace: 'nowrap', // Prevent text from wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis', // Add ellipsis if text overflows
      },
    },
    {
        name: "Data Type",
        selector: (row) => row.type,
        sortable: true,
        width: "200px",
        cell: (row) => (
          <div
            style={{
              backgroundColor: getDataTypeColor(row.type),
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              fontWeight: "bold",
              whiteSpace: 'nowrap', // Prevent text from wrapping
              overflow: 'hidden',
              textOverflow: 'ellipsis', // Add ellipsis if text overflows
            }}
          >
            {row.type}
          </div>
        ),
      },
    {
      name: "Date",
      selector: (row) => row.Date,
      sortable: true,
      width: "150px",
      compact: true,
      cell: row => <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.Date}</div>,
    },
  ];

  return (
    <div>
      <main className="content">
        <div className="mb-3">
            <h1 className="h3 d-inline align-middle"><strong>Knowledge </strong> Data</h1>
          </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <div className="row">
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4"></div>
                  <div className="col-lg-4">
                    <input
                      type="text"
                      placeholder="Search data..."
                      className="form-control mb-3"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : filteredData.length === 0 ? (
                  <p>No data available</p>
                ) : (
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    responsive
                    highlightOnHover
                    pointerOnHover
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    expandOnRowClicked
                  />
                )}
                <br />
                <div
                  id="getError"
                  className={`alert alert-warning ${error ? "" : "d-none"}`}
                >
                  {error}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}