import React from 'react';
import { Typography, Link, Box } from '@mui/material';

function Footer() {
  const year = new Date().getFullYear();

  return (
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 2, // Added padding for visual separation
        backgroundColor: '#f5f5f5', // Optional background color
        [theme => theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
          },
      }}
    >
          <Typography variant="body2" color="textSecondary">
              © {year},
              <Link href="https://kloneit.net" color="inherit" target="_blank" rel="noopener noreferrer">
                 <strong>KlonIT</strong>
              </Link>
               . All rights reserved.
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, [theme => theme.breakpoints.down('sm')]: {
              marginTop: 1,
             } }}>
              <Link href="/support" variant="body2" color="textSecondary">Support</Link>
              <Link href="/help" variant="body2" color="textSecondary">Help Center</Link>
              <Link href="/privacy" variant="body2" color="textSecondary">Privacy</Link>
              <Link href="/terms" variant="body2" color="textSecondary">Terms</Link>
          </Box>
      </Box>
  );
}

export default Footer;